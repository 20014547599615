import {
  FunctionComponent,
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CategoriesView,
  WheelBlock,
  DiscountBanner,
  EventBanner,
  BattlePassBanner,
  Stream,
  Distributions,
  GiftSkin,
} from '../components/home';
import { useProfile } from '~components/profile/hooks';
import { useConfig } from '~components/hooks';
import _ from 'lodash';
import { Helmet } from 'react-helmet';

import BgHome2 from '../scss/images/bg-home-2.webp';
import BgHome2_768 from '../scss/images/bg-home-2-768px.webp';
import BgHome2_480 from '../scss/images/bg-home-2-480px.webp';

import '../scss/pages/_home.scss';
import './container.scss';
import './homePage.scss';
import { gameModeData } from '~frontend/components/home/data/gameModeData';
import GameModeList from '~frontend/components/home/game-mode-list/gameModeList';
import HomeFilters from '~frontend/components/home/home-filters/HomeFilters';
import { HomeFiltersMob } from '~frontend/components/home/home-filters-mob/HomeFiltersMob';
import { Stats } from '~frontend/components/home/stats/Stats';
import TextDropdown from '~frontend/components/home/text-dropdown/TextDropdown';
import Slider1 from '~frontend/components/home/Slider';
import { useLocation } from 'react-use';
const LazyHomeFilters = lazy(
  () => import('~frontend/components/home/home-filters/HomeFilters'),
);
const Slider = lazy(() => import('~frontend/components/home/Slider'));

const Home: FunctionComponent = () => {
  const { t } = useTranslation();
  const getProfile = useProfile();
  const getConfig = useConfig();
  const [showSlider, setShowSlider] = useState(false);
  const location = useLocation();

  const showSeoText = location.pathname === '/' || location.pathname === '/en';

  //Добавляем состояние для хранения категорий
  const [categoryOptions, setCategoryOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const [recomendedfilters, setRecomendedFilters] = useState({
    category: 'Recommended',
    minPrice: undefined,
    maxPrice: undefined,
    sortOrder: 'Lowest price first',
    enoughBalance: false,
  });

  const [filters, setFilters] = useState({
    category: 'All',
    minPrice: undefined,
    maxPrice: undefined,
    sortOrder: 'Lowest price first',
    enoughBalance: false,
  });

  const filt = {
    category: 29,
  };

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  useEffect(() => {
    setIsClient(true);
    const timer = setTimeout(() => {
      setShowSlider(true);
    }, 0); // Задержка в 1 секунду

    return () => clearTimeout(timer);
  }, []);

  const sliderItems = [
    getConfig?.getBattlePass ? (
      <BattlePassBanner key="battlePassBanner" />
    ) : null,
    getConfig?.getEvent ? <EventBanner key="eventBanner" /> : null,
  ].filter(Boolean);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  const schema = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: getConfig?.seo?.title,
      description: getConfig?.seo?.description,
      image: 'https://cdn.cscase.co/cscase/1050csgoskinshover.png',
      sku: 1,
      brand: {
        '@type': 'Brand',
        name: 'CSCASE',
      },
      review: {
        '@type': 'Review',
        reviewRating: {
          '@type': 'Rating',
          ratingValue: '5',
          bestRating: '5',
        },
        author: {
          '@type': 'Person',
          name: `${t('User_few')} CSCASE`,
        },
      },
      aggregateRating: {
        '@type': 'AggregateRating',
        ratingValue: '5',
        ratingCount: 1827,
      },
      offers: {
        '@type': 'AggregateOffer',
        offerCount: '100',
        lowPrice: 5,
        highPrice: 1499,
        priceCurrency: getConfig?.getCurrency?.char,
      },
    }),
  };

  const isDiscount =
    getConfig?.getSaleTime && (getConfig.isAnonDiscount || getConfig?.discount);

  const isMobile =
    getConfig?.userAgent.match(
      /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i,
    ) ?? false;

  const handleFilterChange = (newFilters) => {
    setFilters((prev) => ({ ...prev, ...newFilters }));
  };

  const buildWhereClause = () => {
    const where = {} as any;

    if (filters.category !== 'All') {
      where.categoryName = filters.category;
    }

    if (filters.minPrice !== undefined) {
      where.price = where.price || {};
      where.price.gte = filters.minPrice;
    }

    if (filters.maxPrice !== undefined) {
      where.price = where.price || {};
      where.price.lte = filters.maxPrice;
    }

    if (filters.enoughBalance) {
      where.enoughBalance = true;
    }

    return where;
  };

  const where = buildWhereClause();

  return (
    <>
      <Helmet script={[schema]} />
      <Helmet>
        <link rel="preconnect" href="https://trustunboxing.com" />
        <link rel="preconnect" href="https://www.googletagmanager.com" />

        <link rel="dns-prefetch" href="https://trustunboxing.com" />
        <link rel="dns-prefetch" href="https://www.googletagmanager.com" />
      </Helmet>
      {/* {!isMobile && <WheelBlock />} */}
      <Stream />
      {/* <CategoryNavigation /> */}
      {isDiscount ? (
        <section className="main-bannes-sales">
          <DiscountBanner />
        </section>
      ) : null}
      {_.size(getConfig?.seo?.vkDist) > 0 && (
        // <a
        //   className="knife-block"
        //   href={getConfig?.seo?.vkDist}
        //   target="_blank"
        //   rel="noreferrer"
        // >
        //   <div className="knife-block-inside"></div>
        // </a>
        // <GiftSkin />
        <></>
      )}

      <Suspense fallback={<div className="banner_slider">Loading...</div>}>
        <Slider items={sliderItems} />
      </Suspense>

      {/* {sliderItems ? <Slider1 items={sliderItems} /> : <></>} */}

      <div className="container">
        <CategoriesView
          filters={filters}
          isRecomended={true}
          onCategoriesLoaded={setCategoryOptions}
        />
        <div className="game_giveaways_wrap">
          <GameModeList gameModes={gameModeData} isMobile={isMobile} />
          {getConfig?.seo?.distributionActive && isMobile && <Distributions />}
        </div>
        <HomeFiltersMob
          onFilterChange={handleFilterChange}
          profile={getProfile}
          categoryOptions={[{ value: 'All', label: 'All' }, ...categoryOptions]}
        />
        <Suspense fallback={<></>}>
          <LazyHomeFilters
            onFilterChange={handleFilterChange}
            profile={getProfile}
            isProfileFilter={false}
            categoryOptions={[
              { value: 'All', label: 'All' },
              ...categoryOptions,
            ]}
          />
        </Suspense>

        <CategoriesView
          filters={filters}
          isRecomended={false}
          onCategoriesLoaded={setCategoryOptions}
        />
        <Stats />
        {showSeoText && <TextDropdown />}
      </div>

      {/* {getConfig?.getBattlePass ? (
        <section
          className={`open-cases  ${
            !getProfile && 'open-cases-home'
          } open-cases-breezy open-cases-battle-pass`}
        >
          <BattlePassBanner />
        </section>
      ) : getConfig?.getEvent ? (
        <section
          className={`open-cases  ${
            !getProfile && 'open-cases-home'
          } open-cases-event open-cases-breezy`}
        >
          <EventBanner />
        </section>
      ) : (
        !getProfile && (
          <section className="open-cases open-cases-home">
            <img
              className="banner-img"
              src={BgHome2}
              srcSet={`${BgHome2_480} 480w, ${BgHome2_768} 768w, ${BgHome2} 1920w`}
              sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, 1920px"
              alt="Banner image"
            />
            <section>
              <h1
                dangerouslySetInnerHTML={{
                  __html: t('Open Counter-Strike Global Offensive cases'),
                }}
              ></h1>
              <h3>{t('Withdraw prizes to yourself on Steam')}!</h3>
            </section>
          </section>
        )
      )} */}
    </>
  );
};

export default Home;
